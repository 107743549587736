import { navigate } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { slide as Menu } from "react-burger-menu";
import { signInLink } from "../../constants";
import Button from "../Buttons/Button";
import "./burger-menu.scss";

const BurgerMenu = (props) => {
    const handleClick = () => {
        navigate(signInLink);
    };

    return (
        <Menu right disableAutoFocus>
            <Button rounded fullWidth onClick={handleClick}>Sign-In</Button>
            <p className="mt-3 fs-small">Talent Mapper is currently invite only.</p>
        </Menu>
    );
};

BurgerMenu.propTypes = {
    outerContainerId: PropTypes.string,
    pageWrapId: PropTypes.string,
};

BurgerMenu.defaultProps = {
    outerContainerId: "outer-container",
    pageWrapId: "page-wrap",
};

export default BurgerMenu;
