import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import "./buttons.scss";

const Button = (props) => {
    const { className } = props;
    return (
        <button
            onClick={props.onClick}
            className={classNames({ outline: props.outline, rounded: props.rounded, fullwidth: props.fullWidth }, className, "tm-button")}
        >
            {props.children}
        </button>
    );
};

Button.propTypes = {
    outline: PropTypes.bool,
    onClick: PropTypes.func,
    rounded: PropTypes.bool,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    outline: false,
    fullWidth: false,
    rounded: false,
    label: "click me",
    onClick: () => {},
};

export default Button;
