import React from "react";
import BurgerMenu from "../components/BurgerMenu/BurgerMenu";
import fullNameLogo from "../images/fullname-logo.png";
import "../scss/style.scss";

const IndexPage = () => {
    return (
        <div id="outer-container">
            <BurgerMenu />
            <main id="page-wrap" className="inner-container">
                <div className="logo-container">
                    <img src={fullNameLogo} alt="Talent Mapper logo with map pin" />
                </div>
            </main>
        </div>
    );
};

export default IndexPage;
